<template>
  <div class="n-header">
    <div class="container">
      <!-- logo -->
      <div class="n-logo">
        <!-- <img src="/imgs/logo.png" alt="" /> -->
        <h2>春日汽车网 </h2>
      </div>
      <!-- 导航 -->
      <div class="n-list">
        <a href="javascript:;" @click="goHome()">网站首页</a>
        <a href="javascript:;" @click="goAc()">行业资讯</a>
        <!-- <a href="javascript:;" @click="goInfo()">关于我们</a> -->
        <a href="javascript:;" @click="goXy()">用户协议</a>
        <!-- <a href="/#/ac3">服务项目</a> -->
      </div>
      <!-- 登录注册 -->
      <div class="n-login">
        <span class="n-login" @click="goLogin()" v-show="this.LoggedShow"> 登录 </span>
        <span class="n-login" @click="goRegister()" v-show="this.LoggedShow"> 注册 </span>

        <!-- 用户名 -->
        <span class="n-info" v-show="!this.LoggedShow" v-text="this.nv_user.Username"
          ><i class="iconfont icon-denglu"></i
        ></span>

        <!-- 退出登录 -->
        <span class="n-info" @click="tolg()" v-show="!this.LoggedShow">
          <i class="iconfont icon-tuichudenglu"></i>
          退出登录
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 按钮显示/隐藏
      LoggedShow: true,
      // 获取到 user 用户信息
      nv_user: JSON.parse(window.localStorage.getItem("user")),
      // 存储状态
      state: JSON.parse(window.localStorage.getItem("state")),
      k: this.$route.query.k,
      swiperOption: {
        autoplay: true,
        loop: true,
        // effect: "cube",
        cubeEffect: {
          shadowOffset: 100,
          shadowScale: 0.6,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // 轮播数组
      swiperList: [
        {
          id: "1",
          img: "/imgs/s1.jpg",
        },
        {
          id: "2",
          img: "/imgs/s2.jpg",
        },
      ],
    };
  },
  mounted() {
    this.logged();
  },
  methods: {
    // 登录时隐藏逻辑
    logged() {
      if (this.state == "0") {
        this.LoggedShow = false;
      }
    },
    // 登录跳转
    goLogin() {
      this.$router.push("/login");
    },
    // 注册跳转
    goRegister() {
      this.$router.push("/register");
    },
    // 退出登录
    tolg() {
      if (this.k) {
        window.localStorage.setItem("state", "10");
        window.localStorage.removeItem("k");
        this.$router.push("/");
        location.reload();
      } else {
        window.localStorage.setItem("state", "10");
        this.$router.push("/");
        location.reload();
      }
    },
    // 首页
    goHome() {
      this.$router.push("/");
    },
    // 资讯
    goAc() {
      this.$router.push("/ac1");
    },
    // 关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-header {
  width: 100%;
  height: 500px;
  position: relative;
  // background-color: pink;
  background: url("./../../public/imgs/n1.jpg") no-repeat center;
  background-size: cover;
  .container {
    height: 100px;
    border-bottom: 1px solid #fff;
    // @include flex();
    display: flex;
    align-items: center;
    // logo
    .n-logo {
      width: 330px;
      height: 80px;
      display: flex;
      align-items: center;
      img {
        // width: 100%;
        height: 100%;
      }
      h2 {
        color: #fff;
        font-size: 18px;
        margin-left: 20px;
        font-weight: bold;
      }
    }
    // 导航
    .n-list {
      width: 450px;
      height: 100%;
      // background-color: red;
      display: flex;
      align-items: center;
      margin-left: 80px;
      margin-right: 200px;
      a {
        flex: 1;
        height: 100%;
        line-height: 100px;
        text-align: center;
        font-size: 14px;
        color: #fff;
        transition: all 0.2s;
        font-weight: bold;
        &:hover {
          // color: $colorZ;
        }
      }
    }
    // 登录注册
    .n-login {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        margin: 0 10px;
        transition: all 0.2s;
        background-color: #fff;
        width: 60px;
        height: 30px;
        display: inline-block;
        color: #333;
        font-size: 14px;
        text-align: center;
        line-height: 30px;
        transition: all 0.2s;
        &:hover {
          background-color: $colorZ;
          color: #fff;
        }
      }
    }
  }
}
</style>
